import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layoutOB';
import SubHeader from '../components/SubHeader';
import SignInMain from '../components/SignInMain';
import { AuthUserContext } from '../components/Session';
import '../scss/style.scss';
import sakai from '../images/top_sakai.png';
import ikeda from '../images/top_ikeda4.png';
import player from '../images/top_player.png';
import digital from '../images/digital_team.png';
import kugayamaLogo from '../images/footer_title.png';
import manabe from '../images/ob/manabe.jpg';
import takeda from '../images/ob/takeda-top.jpg';
import tezuka from '../images/tezuka.jpg';
import * as ROUTES from '../constants/routes';

const ObHome = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <ObHomeAuth authUser={authUser} />
      ) : (
        <ObHomeNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

class ObHomeAuth extends React.Component {

  render() {
    return (
      <Fragment>
      <title>OBページ |國學院大學久我山高等学校　バスケットボール部</title>
      <div className="obBackground">
      <div>
      <img alt="tezuka" className="tezukaImg" src={tezuka} />
      </div>
      <div className="ob-content-title">2020年 ハードワーカー</div>
      <div className="blue-board">
      <div className="obHardworkers">

      <div className="obHardworker">
        <Link to={ROUTES.OBSAKAI}>
          <img alt="sakai" className="obHardworkerImg" src={sakai} />
          <div className="obHardworkerName">HC: 酒井良幸</div>
          </Link>
      </div>

      <div className="obHardworker">
        <Link to={ROUTES.OBIKEDA}>
          <img alt="sakai" className="obHardworkerImg" src={ikeda} />
          <div className="obHardworkerName">AC: 池田透</div>
          </Link>
      </div>

      <div className="obHardworker">
        <Link to={ROUTES.OBPLAYER}>
          <img alt="sakai" className="obHardworkerImg" src={player} />
          <div className="obHardworkerName">選手一覧</div>
          </Link>
      </div>

      <div className="obHardworker">
        <Link to={ROUTES.OBDIGITAL}>
          <img alt="sakai" className="obHardworkerImg" src={digital} />
          <div className="obHardworkerName">デジタル運営</div>
          </Link>
      </div>

      </div>
      </div>

      <div className="ob-content-title">OBインタビュー</div>
      <div className="blue-board">
      <div className="ob-content-card">

      <div className="board-ob-item">
      <Link to={ROUTES.TAKEDA}>
      <img className="ob-ajust" alt="manabe" src={takeda} />
      <div className="ob-card-title">B.LEAGUE  プロ選手</div>
      <div className="ob-card-title">竹田　謙</div>
      </Link>
      </div>

      <div className="board-ob-item">
      <Link to={ROUTES.MANABE}>
      <img className="ob-ajust" alt="manabe" src={manabe} />
      <div className="ob-card-title">大手証券会社  マーケティング部</div>
      <div className="ob-card-title">眞部  径</div>
      </Link>
      </div>

      </div>
      </div>

      </div>
    </Fragment>
);
}
}

const ObHomeNonAuth = () => (
<div className="obBackground">
<title>OBページ |國學院大學久我山高等学校　バスケットボール部</title>
<SignInMain />
</div>
);

export default () => (
  <Layout>
  <ObHome />
  <div className="footer-main">
    <div className="footer-content">
    <div className="footer-title"><img alt="Homepage" src={kugayamaLogo} /></div>
    <div className="footer-text">〒168-0082　東京都杉並区久我山1-9-1</div>
    <div className="footer-text">phone:03-3334-1151 ／ fax:03-3335-1233</div>
    <div className="footer-text">監督:酒井良幸</div>
    </div>
    <div className="footer-content">
<iframe className="googleMap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.7307852166623!2d139.5914156152588!3d35.68363033019375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f1e429a79be9%3A0xd9a09d80d787e2c3!2z5ZyL5a246Zmi5aSn5a245LmF5oiR5bGx5Lit5a2m5qChIOmrmOetieWtpuagoQ!5e0!3m2!1sja!2sjp!4v1595002200302!5m2!1sja!2sjp" width="600" height="450" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
    </div>
</div>
    <div className="copyright">
Copyright ©　國學院大學久我山高等学校バスケットボール部, All Rights Reserved.
    </div>
  </Layout>
);
